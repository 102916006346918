






































































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default defineComponent({
  name: 'uOttawa1321Lab3FlowChart',
  components: {STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        explanation: null,
        language: 'en',
      },
      flowChart: [] as File[],
      rules: [(v: string) => v?.length <= 2500 || 'Max 2500 characters'],
      questions: [
        {
          en: 'Briefly explain the steps that you outlined in the flow chart that is to be uploaded below.',
          fr: "Expliquez brièvement les étapes que vous avez détaillées dans l'organigramme qui doit être téléversé ci-dessous.",
        },
        {
          en: 'Please upload your flow chart of the separation process using the upload field below. You should only upload a single file.',
          fr: "S'il vous plaît, téléversez votre organigramme du processus de séparation en utilisant le champ de téléversement ci-dessous. Vous ne devez téléverser qu'un seul fichier.",
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
  computed: {
    attachments(): File[] {
      return [...this.flowChart];
    },
  },
});
